* *::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.error-message {
  color: #f44336;
  font-size: 0.75em;
}

.input-label-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-flow: column-reverse;
  flex-flow: column-reverse;
  position: relative;
}

.input-label-container label {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 5px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  position: absolute;
  top: -2.4em;
  left: -0.5em;
  font-size: 0.75em;
  font-family: Arial, Helvetica, sans-serif;
}

.input-label-container input {
  width: 100%;
  height: 1.1876em;
  padding: 18.5px 14px;
  border-radius: 0.25em;
  border: 1px solid #aaa;
  font-size: 15px;
}

.input-label-container input::-webkit-input-placeholder {
  opacity: 0;
  -webkit-transition: inherit;
  transition: inherit;
}

.input-label-container input:hover {
  border: 1px solid;
}

.input-label-container input:focus {
  outline: 0;
  border: 1px solid #ccc;
  border: 2px solid #3f51b5 !important;
  padding: 17.5px 13px;
}

.input-label-container input:not(:placeholder-shown) {
  outline: 0;
  border: 1px solid #ccc;
}

.input-label-container input:placeholder-shown + label {
  cursor: text;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-transform-origin: -25px 0px;
  transform-origin: -25px 0px;
  color: #999999;
  -webkit-transform: translate(0, 2.125rem) scale(1.5);
  transform: translate(0, 2.125rem) scale(1.5);
}

.input-label-container input:focus::-webkit-input-placeholder {
  opacity: 1;
}

.input-label-container input:not(:placeholder-shown) + label {
  -webkit-transform: translate(0, 0) scale(1);
  transform: translate(0, 0) scale(1);
  cursor: pointer;
  -webkit-transform: translate(15px, 18px);
  transform: translate(15px, 18px);
  background-color: #fff;
}

.input-label-container input:focus + label {
  -webkit-transform: translate(0, 0) scale(1);
  transform: translate(0, 0) scale(1);
  cursor: pointer;
  color: #3f51b5;
  -webkit-transform: translate(15px, 18px);
  transform: translate(15px, 18px);
  background-color: #fff;
}

.nc-container {
  float: left;
  position: absolute;
  z-index: 3;
  width: 280px;
  min-height: 296px;
  background: #fff;
  -webkit-box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.2);
  box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  overflow: hidden;
  padding: 10px;
}

.nc-container .nc-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 45px;
}

.nc-container .nc-head .nc-button {
  float: left;
  width: 45px;
  height: 100%;
  position: relative;
}

.nc-container .nc-head .nc-button .nc-inner {
  float: left;
  width: 30px;
  height: 30px;
  background: #f4f4f4;
  border-radius: 100%;
  line-height: 30px;
  text-align: center;
}

.nc-container .nc-head .nc-button .nc-inner .nc-left-arrows {
  width: 6px;
  height: 6px;
  border-left: 2px solid #888;
  border-bottom: 2px solid #888;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 50%;
  top: 50%;
  margin-left: -2px;
  margin-top: -4px;
  position: relative;
  display: block;
  float: left;
}

.nc-container .nc-head .nc-button .nc-inner .nc-left-arrows::after {
  content: "";
  left: 3px;
  top: -5px;
  width: 6px;
  height: 6px;
  border-left: 2px solid #888;
  border-bottom: 2px solid #888;
  position: relative;
  display: block;
  float: left;
}

.nc-container .nc-head .nc-button .nc-inner .nc-left-arrow {
  width: 6px;
  height: 6px;
  border-left: 2px solid #888;
  border-bottom: 2px solid #888;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 50%;
  top: 50%;
  margin-left: -2px;
  margin-top: -4px;
  position: relative;
  display: block;
  float: left;
}

.nc-container .nc-head .nc-button .nc-inner .nc-right-arrows {
  width: 6px;
  height: 6px;
  border-left: 2px solid #888;
  border-bottom: 2px solid #888;
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
  left: 50%;
  top: 50%;
  margin-left: -2px;
  margin-top: -4px;
  position: relative;
  display: block;
  float: left;
}

.nc-container .nc-head .nc-button .nc-inner .nc-right-arrows::after {
  content: "";
  left: 3px;
  top: -5px;
  width: 6px;
  height: 6px;
  border-left: 2px solid #888;
  border-bottom: 2px solid #888;
  position: relative;
  display: block;
  float: left;
}

.nc-container .nc-head .nc-button .nc-inner .nc-right-arrow {
  width: 6px;
  height: 6px;
  border-left: 2px solid #888;
  border-bottom: 2px solid #888;
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
  left: 50%;
  top: 50%;
  margin-left: -2px;
  margin-top: -4px;
  position: relative;
  display: block;
  float: left;
}

.nc-container .nc-head .nc-button .nc-inner:hover {
  cursor: pointer;
  background: #eee;
}

.nc-container .nc-head .nc-button .nc-inner:hover span {
  border-color: #555 !important;
}

.nc-container .nc-head .ndpch-container {
  float: left;
  width: 120px;
  height: 100%;
}

.nc-container .nc-head .ndpch-container .ndpchc-year {
  float: left;
  width: 100%;
  font-size: 20px;
  color: #666;
  text-align: center;
}

.nc-container .nc-head .ndpch-container .ndpchc-month {
  float: left;
  width: 100%;
  font-size: 12px;
  color: #666;
  font-weight: 700;
  text-align: center;
}

.nc-container .nc-body {
  float: left;
  width: 100%;
}

.nc-container .nc-body .nc-day-container {
  position: relative;
  display: block;
  float: left;
  width: 14.285%;
  height: 19.6666%;
}

.nc-container .nc-body .nc-day-container .cdc-day {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-weight: 300;
  color: #444;
  text-align: center;
  position: relative;
  display: block;
  float: left;
}

.nc-container .nc-body .nc-day-container .cdc-day span {
  position: relative;
  display: block;
  float: left;
  color: #444;
  background: none;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  left: 50%;
  top: 50%;
  border-radius: 100%;
  line-height: 30px;
  font-weight: 700;
}

.nc-container .nc-body .nc-day-container .cdc-day span:hover {
  cursor: pointer;
  background: #eee;
}

.nc-container .nc-body .disabled {
  pointer-events: none;
}

.nc-container .nc-body .disabled .cdc-day {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-weight: 300;
  color: #444;
  text-align: center;
  position: relative;
  display: block;
  float: left;
}

.nc-container .nc-body .disabled .cdc-day span {
  position: relative;
  display: block;
  float: left;
  color: #ddd;
  background: #fff !important;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  left: 50%;
  top: 50%;
  border-radius: 100%;
  line-height: 30px;
}

.nc-container .nc-body .disabled .cdc-day span:hover {
  cursor: pointer;
  background: #eee;
}

.nc-container .nc-body .highlight .cdc-day {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-weight: 300;
  color: #444;
  text-align: center;
  position: relative;
  display: block;
  float: left;
}

.nc-container .nc-body .highlight .cdc-day span {
  position: relative;
  display: block;
  float: left;
  color: #444;
  background: #efdbca;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  left: 50%;
  top: 50%;
  border-radius: 100%;
  line-height: 30px;
}

.nc-container .nc-body .highlight .cdc-day span:hover {
  cursor: pointer;
  background: #eee;
}

.nc-container .nc-body .highlight-green .cdc-day {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-weight: 300;
  color: #444;
  text-align: center;
  position: relative;
  display: block;
  float: left;
}

.nc-container .nc-body .highlight-green .cdc-day span {
  position: relative;
  display: block;
  float: left;
  color: #444;
  background: #d4e2cb;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  left: 50%;
  top: 50%;
  border-radius: 100%;
  line-height: 30px;
}

.nc-container .nc-body .highlight-green .cdc-day span:hover {
  cursor: pointer;
  background: #eee;
}

.nc-container .nc-body .inc-container {
  position: relative;
  display: block;
  float: left;
  width: 100%;
  height: 100%;
}

.nc-container .nc-body .inc-container .cc-head {
  width: 100%;
  height: 30px;
  margin-top: 10px;
  position: relative;
  display: block;
  float: left;
}

.nc-container .nc-body .inc-container .cch-name {
  width: 14.285%;
  height: 30px;
  line-height: 30px;
  font-weight: 700;
  color: #666;
  font-size: 12px;
  text-align: center;
  position: relative;
  display: block;
  float: left;
}

.nc-container .nc-body .inc-container .cc-body {
  width: 100%;
  height: 162px;
  position: relative;
  display: block;
  float: left;
}
