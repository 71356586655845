* *::before,
*::after {
  box-sizing: border-box;
}

.error-message {
  color: #f44336;
  font-size: 0.75em;
}

.input-label-container {
  display: flex;
  flex-flow: column-reverse;
  position: relative;
  label {
    width: fit-content;
    padding: 0 5px;
    transition: all 0.2s;
    touch-action: manipulation;
    position: absolute;
    top: -2.4em;
    left: -0.5em;
    font-size: 0.75em;
    font-family: Arial, Helvetica, sans-serif;
  }
  input {
    @include dimension(1.1876em);
    padding: 18.5px 14px;
    border-radius: 0.25em;
    border: 1px solid #aaa;
    font-size: 15px;
    &::-webkit-input-placeholder {
      opacity: 0;
      transition: inherit;
    }
    &:hover {
      border: 1px solid;
    }
    &:focus {
      @include focus-and-has-value(true);
    }
    &:not(:placeholder-shown) {
      @include focus-and-has-value;
    }
    &:placeholder-shown + label {
      cursor: text;
      width: fit-content;
      padding: 0 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      transform-origin: -25px 0px;
      color: #999999;
      transform: translate(0, 2.125rem) scale(1.5);
    }
    &:focus::-webkit-input-placeholder {
      opacity: 1;
    }
    &:not(:placeholder-shown) + label {
      @include input-focus-and-label;
    }
    &:focus + label {
      @include input-focus-and-label(true);
    }
  }
}

.nc-container {
  float: left;
  position: absolute;
  z-index: 3;
  width: 360px;
  min-height: 350px;
  background: #fff;
  box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  overflow: hidden;
  padding: 25px 30px;

  .nc-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include dimension(45px);

    .nc-button {
      float: left;
      @include dimension(100%, 45px);
      position: relative;

      .nc-inner {
        float: left;
        @include dimension(35px, 35px);
        background: #f4f4f4;
        border-radius: 100%;
        line-height: 35px;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -17px;
        margin-top: -17px;

        .nc-left-arrows {
          @include nc-head-arrow(45deg, true);
        }

        .nc-left-arrow {
          @include nc-head-arrow(45deg);
        }

        .nc-right-arrows {
          @include nc-head-arrow(225deg, true);
        }

        .nc-right-arrow {
          @include nc-head-arrow(225deg);
        }

        &:hover {
          cursor: pointer;
          background: #eee;
          span {
            border-color: #555 !important;
          }
        }
      }
    }
    .ndpch-container {
      float: left;
      @include dimension(100%, 120px);

      .ndpchc-year {
        @include nc-year-and-month(30px, 27px);
      }
      .ndpchc-month {
        @include nc-year-and-month(15px, 13px);
      }
    }
  }

  .nc-body {
    float: left;
    width: 100%;
    margin-top: 20px;
    .nc-day-container {
      @include position;
      @include dimension(16.6666%, 14.285%);
      @include calendarDay;
    }
    .disabled {
      pointer-events: none;
      @include calendarDay(#fff !important, #ddd);
    }
    .highlight {
      @include calendarDay(#efdbca);
    }
    .highlight-green {
      @include calendarDay(#d4e2cb);
    }
    .inc-container {
      @include position;
      @include dimension(100%);
      .cc-head {
        @include dimension(30px);
        margin-top: 10px;
        @include position;
      }
      .cch-name {
        @include dimension(30px, 14.285%);
        line-height: 30px;
        font-weight: 700;
        color: #666;
        font-size: 12px;
        text-align: center;
        @include position;
      }
      .cc-body {
        @include dimension(270px);
        @include position;
      }
    }
  }
}