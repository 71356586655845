@mixin nc-head-arrow($rot, $isDouble: false) {
  @include dimension(6px, 6px);
  border-left: 2px solid #888;
  border-bottom: 2px solid #888;
  transform: rotate($rot);
  left: 50%;
  top: 50%;
  margin-left: -2px;
  margin-top: -4px;
  @include position(absolute);
  @if ($isDouble) {
    &::after {
      content: "";
      left: 3px;
      top: -5px;
      @include dimension(6px, 6px);
      border-left: 2px solid #888;
      border-bottom: 2px solid #888;
      @include position(absolute);
    }
  }
}

@mixin nc-year-and-month($height, $fontSize) {
  float: left;
  @include dimension($height);
  font-size: $fontSize;
  color: #666;
  font-weight: 200px;
  text-align: center;
}

@mixin position($position: relative) {
  position: relative;
  display: block;
  float: left;
}

@mixin dimension($height, $width: 100%) {
  width: $width;
  height: $height;
}

@mixin calendarDay($background: none, $color: #444) {
  .cdc-day {
    @include dimension(100%);
    font-size: 12px;
    font-weight: 300;
    color: #444;
    text-align: center;
    @include position;
    span {
      @include position;
      color: $color;
      background: $background;
      @include dimension(30px, 30px);
      margin-top: -15px;
      margin-left: -15px;
      left: 50%;
      top: 50%;
      border-radius: 100%;
      line-height: 30px;
      &:hover {
        cursor: pointer;
        background: #eee;
      }
    }
  }
}

@mixin input-focus-and-label($isFocused: false) {
  transform: translate(0, 0) scale(1);
  cursor: pointer;
  @if($isFocused){
    color: #3f51b5;
  }
  transform: translate(15px, 18px);
  background-color: #fff;
}

@mixin focus-and-has-value($isFocused: false) {
  outline: 0;
  border: 1px solid #ccc;
  @if($isFocused){
    border: 2px solid #3f51b5 !important;
    padding: 17.5px 13px;
  }
}