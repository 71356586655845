// All Variables Must Be Declared Here.
// Variables should follow the `$component-state-property-size` formula for consistent naming.
// Eg: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Colors
$white: #fff !default;
$black: #000 !default;
$sky-blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #d32f2f !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;
$primary: #3f51b5 !default;
