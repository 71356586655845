// All the common styles must be here.

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-size: 1rem;
  background: #f5f5f5;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  @include transition(color 0.5s ease);
  &:hover,
  &:focus,
  &:active {
    outline: none !important;
    text-decoration: none !important;
  }
}

.body-loader {
  @include body-loader;
}

.action-button-container {
  border-top: 1px solid rgb(158, 158, 158);
  padding-top: 8px;
  .reset-button {
    margin-right: 8px;
  }
}

.bold {
  font-weight: 700 !important;
}

.error-message {
  color: $red;
  font-size: 90%;
  vertical-align: top;
  &::before {
    content: "⚠";
    font-size: 1rem;
    margin-right: 5px;
    vertical-align: top;
  }
}

.border-bottom-heading {
  font-weight: 500;
  border-bottom: 1px solid rgb(158, 158, 158);
  padding-bottom: 8px;
}

input[readonly] {
  color: rgba(0, 0, 0, 0.38);
}

.round {
  padding: 3px 9px;
  border: 1px solid #000;
  border-radius: 50%;
  white-space: nowrap;
}

.position-relative {
  position: relative;
}

.hidden {
  display: none !important;
}

@media only screen and (max-width: 768px) {
  .hide-on-md {
    display: none;
  }
}

@media only screen and (max-width: 576px) {
  .hide-on-xs {
    display: none;
  }
}

// React Select Style Overwrites Start
.select-md {
  .react-select__control {
    min-height: 56px;
  }
}
.select-sm {
  .react-select__control {
    min-height: 40px;
  }
}
.react-select__menu {
  z-index: 9 !important;
}
// React Select Style Overwrites End

// Custom Select Style Overwrites Start
.select-xs {
  height: 36px;
  .MuiOutlinedInput-inputMarginDense {
    padding-top: 8.5px;
    padding-bottom: 8.5px;
  }
}
// Custom Select Style Overwrites End

// Material table Style Overwrites Start
// TODO: Sandeep - Fix remaining styles for fixed table header table.
.fixed-head-table-container {
  max-height: calc(100vh - 64px);
  .table-bordered {
    border-collapse: separate;
  }
}
.MuiTable-root {
  &.table-bordered {
    border: 1px solid #000;
    thead {
      &.align-center {
        tr {
          th {
            text-align: center;
          }
        }
      }
      tr {
        th {
          border-bottom: 1px solid #000;
          border-right: 1px solid #000;
          font-size: 12px;
        }
      }
      &.fixed-header {
        tr {
          th {
            background: #ddd;
            position: sticky;
            z-index: 1;
          }
        }
        &.header-sm {
          tr {
            th {
              height: 48px;
            }
            &:nth-child(1) {
              th {
                top: 0;
              }
            }
            &:nth-child(2) {
              th {
                top: 48px;
              }
            }
            &:nth-child(3) {
              th {
                top: 96px;
              }
            }
            &:nth-child(4) {
              th {
                top: 144px;
              }
            }
          }
        }
        &.header-md {
          tr {
            th {
              height: 86px;
            }
            &:nth-child(1) {
              th {
                top: 0;
              }
            }
            &:nth-child(2) {
              th {
                top: 86px;
              }
            }
            &:nth-child(3) {
              th {
                top: 172px;
              }
            }
            &:nth-child(4) {
              th {
                top: 258px;
              }
            }
            &:nth-child(5) {
              th {
                top: 344px;
              }
            }
          }
        }
      }
    }
    tbody {
      &.align-center {
        tr {
          td {
            text-align: center;
          }
        }
      }
      tr {
        td {
          border-color: #000;
          border-width: 0 1px 1px 0;
          border-style: solid;
          font-size: 12px;
        }
      }
    }
    tfoot {
      &.align-center {
        tr {
          td {
            text-align: center;
          }
        }
      }
      tr {
        td {
          color: rgba(0, 0, 0, 0.87);
          border-color: #000;
          border-width: 0 1px 1px 0;
          border-style: solid;
          font-size: 12px;
        }
      }
    }
    //TODO: Bikas rename ".fixed-column" to ".fixed-column-right" and replace it wherever it is used
    .fixed-column {
      background: #ddd;
      border-left: 1px solid #000;
      position: sticky;
      right: 0px;
    }
    .fixed-column-left {
      background: #ddd;
      border-left: 1px solid #000;
      position: sticky;
      left: 0px;
    }
    .fixed-block {
      z-index: 2 !important;
    }
    .cell-disabled {
      background-color: #444;
    }
    .cell-light-red {
      background-color: #ffc7cc;
      color: #8a0411;
    }
    &.normal-spacing {
      thead {
        tr {
          th {
            padding: 5px;
          }
        }
      }
      tbody {
        tr {
          td {
            padding: 5px;
          }
        }
      }
    }
  }
}
// Material table Style Overwrites End

// Nepali Datepicker Styles Start

#dateInputGroup {
  display: flex;
  .input-group-append {
    display: flex;
    #resetDateButton {
      background: none;
      border-left: 0;
      border-color: rgba(0, 0, 0, 0.38);
      border-width: 1px;
      color: rgba(0, 0, 0, 0.87);
      cursor: pointer;
      outline: none;
      padding: 0 8px;
    }
  }
  .date-picker-form-control {
    color: rgba(0, 0, 0, 0.87);
    border: 1px solid rgba(0, 0, 0, 0.38);
    padding: 8px;
    font-size: 14px;
    border-radius: 4px 0 0 4px;
    outline: none;
    min-height: 36px;
    &.full-width {
      width: 100%;
    }
    &.input-sm {
      min-height: 40px;
    }
    &.input-md {
      min-height: 56px;
    }
    &.width-sm {
      width: 100px;
    }
  }
}
.inline-datepicker {
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 16px;
  justify-content: flex-end;
  > .nepali-date-picker {
    top: 36px;
    min-width: 300px;
  }
}

// Nepali Datepicker Styles End

// Report Pages Styles Start
.report-date-range-container {
  > h6 {
    &.MuiTypography-subtitle2 {
      padding: 0 8px;
    }
  }
}

.report-heading {
  text-align: center;
  padding: 5px 0;
  margin-bottom: 10px !important;
  background: #c8e6c9;
  color: #1b5e20;
  border: 1px solid #1b5e20;
}

.report-print-btn {
  margin-left: 8px !important;
}

@media screen and (max-width: 600px) {
  .report-print-btn {
    margin-left: 0px !important;
    margin-top: 8px !important;
  }
}

// Report Pages Styles End

// Print Common Styles Start
@media print {
  .print-none {
    display: none !important;
  }
  // Hide scrollbar for Chrome, Safari and Opera
  .fixed-head-table-container::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .fixed-head-table-container {
   -ms-overflow-style: none; 
   scrollbar-width: none; 
}
  @page {
    margin: 48px;
  }
}
// Print Common Styles End

.responsive-table {
  table {
    border-spacing: 0;
    border: 1px solid black;
    min-width: 100%;
    tr {
      :last-child {
        td {
          border-bottom: 0;
          text-align: center;
        }
      }
    }
    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      text-align: center;
    }
  }
}
